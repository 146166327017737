import React, { useEffect } from "react";
import "./staking.scss";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Navbar from "../landing/header/Navbar";
import moment from "moment";
import axios from "axios";
import Environment from "../../utils/Environment";
import GetGemsBalance from "../../hooks/chinaFunction/getGemsBalance";
import GetGemsStaked from "../../hooks/chinaFunction/getGemsStakeAmount";
import StakeGems from "../../hooks/chinaFunction/stakeGems";
import UnStakeGems from "../../hooks/chinaFunction/unstakeGems";
import AllowanceGems from "../../hooks/chinaFunction/allowanceGems";
import ApproveGems from "../../hooks/chinaFunction/approveGems";
import GetGemsStakedIndex from "../../hooks/chinaFunction/getGemsStakedIndex";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import Loader from "../../hooks/loader";
import { useHistory } from "react-router-dom";


const Staking = () => {
    const history = useHistory();
    const { account } = useWeb3React();
    const { GetGemsBal } = GetGemsBalance();
    const { getGemsStaked } = GetGemsStaked();
    const { stakeGems } = StakeGems();
    const { unstakeGems } = UnStakeGems();
    const { allowanceGems } = AllowanceGems();
    const { approveGems } = ApproveGems();
const {getGemsStakedIndex}=GetGemsStakedIndex()

    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false);
    const [amount, setAmount] = useState("5555");
    const [amountError, setAmountError] = useState("");
    const [walletError, setWalletError] = useState("");
    const[stakeIndex,setStakeIndux]=useState(null)

    const [balance, setBalance] = useState(0);
    const [allowance, setAllowance] = useState(0);

    const [minStakeAmount, setMinStakeAmount] = useState(999999999999999);
    const [stakingGasFees, setStakingGasFees] = useState(0);
    const [stakedAmount, setStakedAmount] = useState(0);

    const [stakeEndTime, setStakeEndTime] = useState([]);
    const [stakingData, setStakingData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage] = useState(10); // Define how many items to show per page
    const [orderBy, setOrderBy] = useState("blockTimestamp");
    const [orderDirection, setOrderDirection] = useState("asc");


    const [show1, setShow1] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow1(false);
    const handleShow = () => setShow1(true);

    const [show2, setShow2] = useState(false);

    const handleClose1 = () => {
        setShow2(false);
        history.push('/buy');
    };
    const handleShow1 = () => setShow2(true);

    // const fetchStakingData = async () => {
    //     setLoader1(true)
    //     try {
    //         const response = await axios.post(
    //             "https://api.studio.thegraph.com/query/63987/staking-gems-testnet/12",
    //             {
    //                 query: `
    //             query MyQuery($staker: String, $isClaimed: Boolean,) {
    //               stakeds(where: {staker: $staker}, isClaimed: $isClaimed,) {
                  
    //                 blockTimestamp
    //                 stakeEndTime
    //                 isClaimed
    //               }
    //             }`,
    //                 variables: {
    //                     staker: account,
    //                 },
    //             },
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //             }
    //         );

    //         const resData = response.data.data.stakeds;
    //         if(resData.length>0){
    //         console.log(resData[0], 'resData');
    //         // setStakeIndux(parseInt(resData[0]?.stakerStakeIndex))
    //         setStakingData(resData);
    //         const currentTime = moment();
    //         const endTimeStampInSeconds = parseInt(
    //             resData[resData?.length - 1]?.stakeEndTime
    //         );
    //         const endTimeStamp = moment(endTimeStampInSeconds * 1000); // Convert to milliseconds
    //         if (!endTimeStamp.isValid()) {
    //             setStakeEndTime(currentTime);
    //         } else {
    //             setStakeEndTime(endTimeStamp);
    //         }
    //         setLoader1(false)
    //     }
    //     } catch (error) {
    //         console.error("Error fetching staking data: ", error);
    //         setLoader1(false)
    //     }
    // };



    const fetchStakingData = async () => {
        setLoader1(true);
        var data = JSON.stringify({
          query: `query MyQuery {
            stakeds(where: {isClaimed: false, staker: "${account}"}) {
              stakerStakeIndex
              transactionHash
              stakeEndTime
              staker
              isClaimed
              id
              blockTimestamp
              blockNumber
              amount
            }
          }`,
          variables: {},
        });
        var config = {
          method: "post",
          url: "https://api.studio.thegraph.com/query/63987/staking-gems-testnet/12",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config)
          .then(async function (response) {
            console.log(response, 'response?.data?.data?.investments mainn1 sddasasa');
            const resData = response.data.data.stakeds;
            if(resData.length>0){
            console.log(resData[0], 'resData');
            setStakeIndux(parseInt(resData[0]?.stakerStakeIndex))
            setStakingData(resData);
            const currentTime = moment();
            const endTimeStampInSeconds = parseInt(
                resData[resData?.length - 1]?.stakeEndTime
            );
            const endTimeStamp = moment(endTimeStampInSeconds * 1000); // Convert to milliseconds
            if (!endTimeStamp.isValid()) {
                setStakeEndTime(currentTime);
            } else {
                setStakeEndTime(endTimeStamp);
            }
            
        }
        setLoader1(false)
          })
          .catch(function (error) {
            setLoader1(false);
            console.log(error, "error");
          });
      };
    




    const unstake = async (index) => {
        setLoader(true);
        // console.log([index], '[index]');
        try {

            const gas = await unstakeGems(account, index);
            if (gas) {
                // handleShow1();
                setLoader(false);
                getGemsStakedAmount();
                setTimeout(() => {
                    fetchStakingData();
                }, 5000);

                // getGemsBalanceFunc();
                // setStakingGasFees(gas);
                // handleShow();
            }
        } catch (e) {
            setLoader(false);
            console.error(e);
            toast.error(e?.message);
        }

    };

    const Stake = async () => {
        // console.log(allowance, 'allowancebalance', balance, amount);
        if (account) {
            if (balance >= amount) {
                if (allowance >= amount) {
                    try {
                        setLoader(true);
                        const gas = await stakeGems(account, amount);

                        if (gas) {
                            // stakeAPI();
                            handleClose();
                            handleShow1();
                            setLoader(false);
                            getGemsStakedAmount();
                            getGemsBalanceFunc();
                            setTimeout(() => {
                                fetchStakingData();
                            }, 5000);

                            // setStakingGasFees(gas);
                            // handleShow();
                        }
                    } catch (e) {
                        setLoader(false);
                        console.error(e);
                        toast.error(e?.message);
                    }
                } else {
                    try {
                        setLoader(true);

                        const approve = await approveGems(account);
                        if (approve) {
                            getAllowance();
                            getGemsBalanceFunc();
                            if (balance >= amount) {
                                try {
                                    const gas = await stakeGems(account, amount);
                                    if (gas) {
                                        // stakeAPI();
                                        handleClose();
                                        handleShow1();
                                        setLoader(false);
                                        getGemsStakedAmount();
                                        getGemsBalanceFunc();

                                        setTimeout(() => {
                                            fetchStakingData();
                                        }, 5000);

                                        // setStakingGasFees(gas);
                                        // handleShow();
                                    }
                                } catch (e) {
                                    setLoader(false);
                                    console.error(e);
                                    toast.error(e?.message);
                                }
                            } else {
                                // handleShow2();
                                setLoader(false);
                                toast.error("Not Enough GEMS Balance")
                                //   setTimeout(() => {
                                //     setWalletError("Not Enough GEMS Balance");
                                //   }, 5000);

                            }
                        }
                    } catch (e) {
                        setLoader(false);
                        console.error(e);
                        toast.error(e?.message);
                    }
                }
            }
            else {
                // handleShow2();
                toast.error("Not Enough GEMS Balance")
                // setWalletError("Not Enough GEMS Balance");
            }

        } else {
            // setWalletError("Connect Wallet!");
            handleClose();
            //   setOpenWalletModal(true);
        }
    };

    const getGemsStakedAmount = async () => {
        const staked = await getGemsStaked(account);

        if (staked !== null || staked !== undefined) {
            setStakedAmount(staked);
        }
    };

    const getAllowance = async () => {
        const all = await allowanceGems(account);
        if (all !== null || all !== undefined) {
            setAllowance(all);
        }
    };

    const getGemsBalanceFunc = async () => {
        const bal = await GetGemsBal(account);
        if (bal !== null || bal !== undefined) {
            setBalance(bal);
        }
    };


    //  const StakeIndec=async()=>{
    //     const bal = await getGemsStakedIndex(account);
    //     console.log(bal, 'bal');
    //     if (bal !== null || bal !== undefined) {
    //         setStakeIndux(parseInt(bal)-1)
    //     }
    //  }

    useEffect(() => {
        if (account) {
            // StakeIndec()
            getAllowance();
            getGemsBalanceFunc();
            fetchStakingData();
            getGemsStakedAmount();
        }
    }, [account]);

    return (
        <>
          {loader1 && (
        <Loader />
      )}
            {loader && (
                <Loader text={"Please wait, your transaction is in process"} />
            )}
            <Navbar show={show} setShow={setShow} />
            <section className="main-staking">
                <div className="upperbanner">
                    <div className="custom-container">
                        <h6 className="staketoppara">Golden Discount</h6>
                        <h4 className="stakemainhead">
                            Get upto 7% discount on your purchases
                        </h4>
                        <p>
                            Lock 5,555 GEMS for a period of 1 year and get 7% discount in the first round of every sale and 7% discount in every following round!
                        </p>
                    </div>
                </div>
                <div className="lowerbanner">
                    <div className="custom-container">
                        <div className="newboxes-twice">
                            <div className="single-box">
                                <div className="upper-content">
                                    <h6>basic</h6>
                                    <h4>
                                        <span>0</span> GEMS
                                    </h4>
                                </div>
                                <div className="bottom-content">
                                    <div className="inner-single">
                                        <div className="text">
                                            <img
                                                src="\assets\premium\earnmore.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                            <p>0% Discount in the first round of every sale</p>
                                        </div>
                                        <img
                                            src="\assets\premium\cross.svg"
                                            alt="img"
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="inner-single">
                                        <div className="text">
                                            <img
                                                src="\assets\premium\earnmore.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                            <p>0% Discount in every following round</p>
                                        </div>
                                        <img
                                            src="\assets\premium\cross.svg"
                                            alt="img"
                                            className="img-fluid"
                                        />
                                    </div>

                                </div>
                                <a href="#" className="btn-plan">
                                    CURRENT PLAN
                                </a>
                            </div>
                            <div className="main-bottom-ahead">
                                <div className="single-box activee">
                                    <span className="gradient-activee"></span>
                                    <div className="upper-content">
                                        <div className="d-flex justify-content-between align-item-center">
                                            <h6 style={{ color: "#E2B854" }}>Golden Discount</h6>
                                            {stakingData?.length > 0 &&
                                                moment().isAfter(stakeEndTime) ? (
                                                <p
                                                    className="cdhbdybydbcy"
                                                    style={{ color: "#FF4A4A" }}
                                                >
                                                    Plan expired on{" "}
                                                    {moment(stakeEndTime)?.format("DD/MM/YY")}
                                                </p>
                                            ) : null}
                                        </div>
                                        <h4>
                                            <span>5,555</span> GEMS
                                        </h4>
                                    </div>
                                    <div className="bottom-content">
                                        <div className="inner-single">
                                            <div className="text">
                                                <img
                                                    src="\assets\premium\earn100.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <p>7% Discount in the first round of every sale</p>
                                            </div>
                                            <img
                                                src="\assets\premium\check.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="inner-single">
                                            <div className="text">
                                                <img
                                                    src="\assets\premium\earn100.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <p>7% Discount in every following round</p>
                                            </div>
                                            <img
                                                src="\assets\premium\check.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                        </div>

                                    </div>
                                    {account ?
                                        <>
                                            {stakedAmount <  5555 ? (
                                                <a
                                                    className="btn-plan activee"
                                                    onClick={() => {
                                                        handleShow();
                                                    }}
                                                >
                                                    Upgrade
                                                </a>
                                            ) : stakingData?.length > 0 &&
                                                moment().isBefore(stakeEndTime) &&
                                                stakedAmount >=  5555 ? (
                                                <a className="btn-plan" style={{ flexDirection: "column" }}>
                                                    <h6>CURRENT PLAN</h6>
                                                    <p>
                                                        expires on {moment(stakeEndTime)?.format("DD/MM/YY")}
                                                    </p>
                                                </a>
                                            ) : stakingData?.length > 0 &&
                                                moment().isAfter(stakeEndTime) ? (
                                                <div className="twice-btns">
                                                    <a
                                                        className="withdraw-gems"
                                                        onClick={() => {
                                                            unstake(stakeIndex);
                                                        }}
                                                    >
                                                        Withdraw Gems
                                                    </a>
                                                    <a
                                                        className="upgrade-gems"
                                                        onClick={() => {
                                                            handleShow();
                                                        }}
                                                    >
                                                        upgrade Again
                                                    </a>
                                                </div>
                                            ) : null}
                                        </>
                                        :
                                        <a onClick={() => setShow(true)} className="btn-plan activee">
                                            Connect Wallet
                                        </a>

                                    }
                                    {/* <a href="#" className="btn-plan" style={{ flexDirection: "column" }}>
                                        <h6>CURRENT PLAN</h6>
                                        <p>expires on 6/07/24</p>
                                    </a> */}
                                    {/* <div className="twice-btns">
                                        <a href="#" className="withdraw-gems">
                                            Withdraw Gems
                                        </a>
                                        <a href="#" className="upgrade-gems">
                                            upgrade Again
                                        </a>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                className="stakingmodal premiumstake-modal"
                show={show1}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Get Discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="stakingmodal">
                        <div className="inner-content">
                            <img
                                src="\assets\premium\modalbg.png"
                                alt="img"
                                className="img-fluid modalbg-stake"
                            />
                            <img
                                src="\assets\premium-icon1.svg"
                                alt="img"
                                className="img-fluid"
                            />
                            <h5>
                                Are you sure you want to <br />
                                Become Golden Member?
                            </h5>
                            <p>This will lock your 5,555 GEMS for 1 Year</p>
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                            {/* <p className="text-danger ">{walletError}</p> */}
                        </div>
                        <div className="stakingbtns">
                            <button
                                onClick={() => {
                                    handleClose();
                                }}
                                className="cancelbtn"
                            >
                                Cancel
                            </button>
                            <button onClick={() => {
                                Stake();
                            }} className="confirmbtn">Confirm</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                className="stakingmodal"
                show={show2}
                onHide={handleClose1}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Get Discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="addedmodal">
                        <img
                            src="\assets\tickimg.svg"
                            alt="addedimg"
                            className="addedimg"
                        />
                        <h6 className="successhead">upgraded to Golden User</h6>
                        <p className="succesaspara">
                            Enjoy up to 7% discount on your <br /> purchases!
                        </p>
                        <button
                            className="greybtn w-100"
                            onClick={() => {
                                handleClose1();
                            }}
                        >
                            Okay
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Staking;
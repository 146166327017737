import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getGemsTokenContract } from "../../utils/contractHelpers";

const ApproveGems = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.gems;
  const contract = getGemsTokenContract(tokenAddress, web3);
  const approveGems = useCallback(
    async (account) => {
      let nd = 19000;
      nd = web3.utils.toWei(nd.toString(), "ether");
      try {
        const gas = await contract.methods
          .approve(environment.GemsStaking, nd)
          .estimateGas({ from: account });

        const details = await contract.methods
          .approve(environment.GemsStaking, nd)
          .send({
            from: account,
            gas,
          });
        return details;
      } catch (error) {
        throw error;
      }
    },
    [contract]
  );
  return { approveGems: approveGems };
};
export default ApproveGems;

import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import {NftPurchaseContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const BalacefAccountDop = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress1 = Environment.nftPurchase;
  const contract = NftPurchaseContract(tokenAddress1, web3);
  const balacefAccountDop = useCallback(
    async () => {
      try {
        const buy = await contract.methods.totalSupply().call();
        return buy;
      } catch (error) {
        throw error;
      }
    },
    [contract, account]
  );
  return { balacefAccountDop:balacefAccountDop };
};
export default BalacefAccountDop;
import React, { useEffect, useState } from "react";
import "./claim.scss";
import Navbar from "../landing/header/Navbar";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Loader from "../../hooks/loader";
import BalacefAccountUsdc from "../../hooks/dataFetchers/BalanceOfAccountUsdc";


const Claim = () => {
  const { account, chainId } = useWeb3React();
  const { balacefAccountUsdc } = BalacefAccountUsdc()
  let now = Date.now().toString;
  const [balaceOfUsdc, setBalanceOfUsdc] = useState(null)
  const [mainLoader, setMainLoader] = useState(false);
  const [mainLoader1, setMainLoader1] = useState(false);

  const [show, setShow] = useState(false);
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
if(account){
  balancerOfUsdc()
}
  }, [account]);

  const balancerOfUsdc = async () => {
    try {
      let balace = await balacefAccountUsdc(account);
      if (balace) {
        setBalanceOfUsdc(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  

  return (
    <>
      {mainLoader1 && <Loader />}
      {mainLoader && (
        <Loader text={"Please wait, your transaction is in process"} />
      )}
      <Navbar show={show} setShow={setShow} />
      <section className="claim">
        <div className="custom-container">
          <div className="claim-heading">
            <h6>mY PURCHASES</h6>
          </div>
          <div className="bottom-table">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    {/* <th>Purchased on</th> */}
                    <th>PURCHASE PRICE</th>
                    <th>Amount Spent</th>
                    <th>nftS PURCHASED</th>
                    <th>VION TOKENS YOU GET</th>
                  </tr>
                </thead>
                <tbody>
                 {
                  account&&balaceOfUsdc!=0?
                  <tr>
                  {/* <td>02:46 25/06/23</td> */}
                  <td>$0.013/VION</td>
                  <td>{balaceOfUsdc*375} USDT</td>
                  <td>{balaceOfUsdc} VION Genesis NFT</td>
                  <td>{((balaceOfUsdc*375)/0.013).toFixed(3)} VION</td>
                </tr>
                :
                ""
                 }
                </tbody>
              </table>
            </div>
        
          </div>
        </div>
      </section>
    </>
  );
};

export default Claim;

import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const Timer = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);


  const timer = useCallback(
    async (e) => {
 
      try {
        const buy = await contract.methods.rounds(e).call()
        return buy;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { timer: timer };
};
export default Timer;
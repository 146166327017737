import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getGemsTokenContract } from "../../utils/contractHelpers";

export const GetGemsBalance = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.gems;
  const contract = getGemsTokenContract(tokenAddress, web3);
  const GetGemsBal = useCallback(
    async (account) => {
      const approved = await contract.methods
        .balanceOf(account?.toString())
        .call();
      const bal = parseInt(approved.toString()) / 10 ** 18;
      return bal;
    },
    [contract]
  );

  return { GetGemsBal: GetGemsBal };
};

export default GetGemsBalance;

// import logo from './logo.svg';
import '../../App.scss';
import React, { useState, useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar';

import { useHistory } from "react-router-dom";
function Landing() {
  const [show, setShow] = useState(false);
  const history = useHistory()

  let checkadmin = localStorage?.getItem("accessCode")
 
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  //   if(!checkadmin)
  //   {
  //     history.push("/");
  //   }
  // }, [])


  return (
    <>
    
    <Navbar show={show} setShow={setShow}/>
      <Banner setShow={setShow}/>
    </>
  );
}

export default Landing;
import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import { toast } from "react-toastify";

const PurchaseUsdt = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const UsdtAdress=Environment.busd
  const priceUsdt=10000
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);
  const purchaseUsdt = useCallback(
    async (nft) => {
  let gas
      try {
        let gasPrice=await web3.eth.getGasPrice()
        gasPrice = parseInt(gasPrice) + 3000000000
       gas = await contract.methods
          .purchaseNFTWithUSDT(
            nft
          )
          .estimateGas({
            from: account,
            gasPrice
          });
        const details = await contract.methods
          .purchaseNFTWithUSDT(
            nft
          )
          .send({
            from: account,
            gas:gas,
            gasPrice
          });
        return details;
      } catch (error) {

        // let gasPrice1=await web3.eth.getGasPrice()
        // let gasPrice = parseInt(gasPrice1) + 3000000000
        // gas=gas*gasPrice
        // let nd = web3.utils.fromWei(gas.toString(), "ether");
        // console.log(gas,nd,balance );
        // if(nd>balance){
        //    toast.error("Insufficient ETH for Transaction")
        // }else{
          throw error;
        // }
 
      }
    },
    [contract, account,web3]
  );
  return { purchaseUsdt: purchaseUsdt };
};
export default PurchaseUsdt;

import React, { useState, useEffect } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../header/Navbar";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../hooks/loader";
import EthPrice from "../../../hooks/chinaFunction/ethPrice";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import AllowanceTpl from "../../../hooks/dataFetchers/AllowanceTpl";
import ApproveTpl from "../../../hooks/dataFetchers/ApproveTpl";
import PurchaseUsdt from "../../../hooks/chinaFunction/purchaseUsdt";
import PurchaseEth from "../../../hooks/chinaFunction/purchaseEth";
import BalacefAccount from "../../../hooks/dataFetchers/BalaceOfAccount";
import useWeb3 from "../../../hooks/useWeb3";
import { toast } from "react-toastify";
import ProgressBar from 'react-bootstrap/ProgressBar';
import BalacefAccountDop from "../../../hooks/dataFetchers/balanceOfAccountDop";
const Banner = ({ setShow }) => {
  const { balacefAccountDop } = BalacefAccountDop();
  const web3 = useWeb3();
  const [balanceeUsdt, setBalanceeUsdt] = useState(null);
  const [balaceOfDoppp, setBalanceOfDopp] = useState(null)
  const { balacefAccount } = BalacefAccount();
  const { allowanceTpl } = AllowanceTpl();
  const { account } = useWeb3React();
  const { ethPrice } = EthPrice();
  const { approveTpl } = ApproveTpl();
  const { purchaseUsdt } = PurchaseUsdt();
  const { purchaseEth } = PurchaseEth();
  var now11 = new Date();
  let createdDate = now11?.getTime()
  let currentTime = createdDate / 1000;
  currentTime = parseInt(currentTime)
  const [loaderr, setLoarder] = useState(false);
  const [loaderr1, setLoarder1] = useState(false);
  const [inputFeild, setInputField] = useState(null);
  const [inputFeild1, setInputField1] = useState(null);
  const [mainTag, setMainTag] = useState("ETH");
  const [errortest, setErrTest] = useState("Oops, something went wrong")
  const [tagImge, setTagImge] = useState('/assets/eth-icon.svg')
  const [stakedAmount, setStakedAmount] = useState(0);
  const [ethValueCalcualte, setethValueCalcualte] = useState(null)
  const history = useHistory();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [ethPricevalue, setEthPrice] = useState(0);
  const [totalToken, setTotalToken] = useState(0)
  const [totalToken1, setTotalToken1] = useState(0)
  const [totalNft, setTotalNft] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalAmount1, setTotalAmount1] = useState(0)
  const [tokenprice, settokenprice] = useState(0.013)
  const [nftPrice, setNftPrice] = useState(375.1)
  const [nftPrice1, setNftPrice1] = useState(375)
  const [allowance, setAllowance] = useState(null);
  const [blanceOfEth, setBlanceOfEth] = useState(0);
  const handleClose2 = () => {
    setShow2(false);
  }
  const handleClose = () => {
    setInputField("");
    setInputField1("");
    setShow1(false);
    history.push('/purchases')
  };
  const handleShow = () => setShow1(true);
  useEffect(() => {
    let value = localStorage.getItem('accessCode')
    if (!value) {
      history.push('/')
    }
  }, [])
  const handlerTag = (e) => {
    if (e === 'ETH') {
      setMainTag("ETH")
      setTagImge('/assets/eth-icon.svg')
      setTotalToken(0)
      setTotalToken1(0)
      setTotalAmount(0)
      setTotalAmount1(0)
      setTotalNft(0)
    } else {
      setMainTag("USDT")
      setTagImge('/assets/usdt.svg')
      setTotalToken(0)
      setTotalToken1(0)
      setTotalAmount(0)
      setTotalNft(0)
      setTotalAmount1(0)
    }
  }
  const firstTimeData11 = async () => {
    try {
      let a = await ethPrice();
      if (a) {
        setEthPrice(a);
      }
    } catch (err) {
      console.log(err.message);
    }
  }
  const handleDecrement = (nft) => {
    if (nft != 0) {
      if (mainTag === 'USDT') {
        let b = nft - 1
        let totaltoken = b * nftPrice
        let value = (totaltoken) / tokenprice

        let totaltoken1 = b * nftPrice1
        let value1 = (totaltoken1) / tokenprice
        setTotalToken(value)
        setTotalAmount1(totaltoken1)
        setTotalAmount(totaltoken)
        setTotalNft(b)
        setTotalToken1(value1)
      } else {
        let b = nft - 1
        let totaltoken = (b * nftPrice) / ethPricevalue
        let value = (b * nftPrice) / tokenprice

        let totaltoken1 = (b * nftPrice1) / ethPricevalue
        let value1 = (b * nftPrice1) / tokenprice
        setTotalToken(value)
        setTotalAmount1(totaltoken1)
        setTotalAmount(totaltoken)
        setTotalNft(b)
        setTotalToken1(value1)
      }
    }
  }


  const handleIncrement = (nft) => {
    console.log(nft + parseInt(balaceOfDoppp), 'nft+balaceOfDoppp');
    if (nft + parseInt(balaceOfDoppp) < 1000) {
      if (mainTag === 'USDT') {
        let b = nft + 1
        let totaltoken = b * nftPrice
        let value = (totaltoken) / tokenprice

        let totaltoken1 = b * nftPrice1
        let value1 = (totaltoken1) / tokenprice
        setTotalToken1(value1)
        setTotalToken(value)
        setTotalAmount1(totaltoken1)
        setTotalAmount(totaltoken)
        setTotalNft(b)
      } else {
        let b = nft + 1
        let totaltoken = (b * nftPrice) / ethPricevalue
        let value = (b * nftPrice) / tokenprice

        let totaltoken1 = b * nftPrice1 / ethPricevalue
        let value1 = (b * nftPrice1) / tokenprice
        setTotalToken1(value1)
        setTotalToken(value)
        setTotalAmount1(totaltoken1)
        setTotalAmount(totaltoken)
        setTotalNft(b)
      }
    } else {
      toast.info("You can't purchase additional NFTs due to the limited availability")
    }
  }


  useEffect(() => {
    firstTimeData11()
    alowence()
    balancerOfDop()
    if (account) {
      balaceOf()
      firstTimeData1()
    } else {
      setBlanceOfEth(0)
      setBalanceeUsdt(0)
    }

  }, [account])

  useEffect(() => {
    setInterval(() => {
      balancerOfDop()
    }, 30000);
  }, [])

  const alowence = async () => {
    try {
      let r = await allowanceTpl();
      setAllowance(r);

    } catch (err) {
      console.log(err, 'nooooer23');
    }
  };
  const balaceOf = async () => {
    try {
      let balace = await balacefAccount(account);
      if (balace) {
        setBalanceeUsdt(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const firstTimeData1 = async () => {
    try {
      var balance = await web3.eth.getBalance(account);
      setBlanceOfEth(balance / 10 ** 18);
    } catch (err) {
      console.log(err.message);
    }
  };

  const balancerOfDop = async () => {
    try {
      let balace = await balacefAccountDop(account);
      if (balace) {
        console.log(balace, 'balace');
        setBalanceOfDopp(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const contractCall = async () => {
    setLoarder(true)
    if (mainTag === "USDT") {
      if (balanceeUsdt >= totalAmount1) {
        if (allowance === 0) {
          try {
            let z = await approveTpl(10000000, account);
            if (z) {
              let b = await purchaseUsdt(totalNft)
              if (b) {
                setLoarder(false)
                alowence()
                setShow1(true)
              }
            }

          } catch (err) {
            console.log(err, 'usdt erro');
            if (err?.message?.toLowerCase().includes("denied")) {
              setErrTest("User denied transaction")
            }

            else if (err?.message?.toLowerCase().includes("allowance")) {
              setErrTest("Insufficient balance for the transaction")
            } else {
              setErrTest("Oops, something went wrong")
            }
            setShow2(true)
            setLoarder(false)
          }
        } else {
          if (totalAmount1 > allowance) {
            try {
              let zx = await approveTpl(0, account);
              if (zx) {
                let z = await approveTpl(10000000, account);
                if (z) {
                  let b = await purchaseUsdt(totalNft)
                  if (b) {
                    setLoarder(false)
                    alowence()
                    setShow1(true)
                  }
                }
              }
            } catch (err) {
              console.log(err, 'usdt erro');
              if (err?.message?.toLowerCase().includes("denied")) {
                setErrTest("User denied transaction")
              }

              else if (err?.message?.toLowerCase().includes("allowance")) {
                setErrTest("Insufficient balance for the transaction")
              } else {
                setErrTest("Oops, something went wrong")
              }
              setShow2(true)
              setLoarder(false)
            }
          } else {
            try {
              let b = await purchaseUsdt(totalNft)
              if (b) {
                setLoarder(false)
                alowence()
                setShow1(true)
              }
            } catch (err) {
              console.log(err, 'usdt erro');
              if (err?.message?.toLowerCase().includes("denied")) {
                setErrTest("User denied transaction")
              }

              else if (err?.message?.toLowerCase().includes("allowance")) {
                setErrTest("Insufficient balance for the transaction")
              } else {
                setErrTest("Oops, something went wrong")
              }
              setShow2(true)
              setLoarder(false)
            }
          }

        }
      } else {
        setLoarder(false)
        toast.error("Insufficient balance")

      }
    } else {

      if (blanceOfEth >= totalAmount1) {

        try {
          let x = await purchaseEth(totalNft, totalAmount);
          if (x) {
            setLoarder(false)
            setShow1(true)
          }
        } catch (err) {
          console.log(err, 'eth erro');
          if (err?.message?.toLowerCase().includes("denied")) {
            setErrTest("User denied transaction")
          }

          else if (err?.message?.toLowerCase().includes("allowance")) {
            setErrTest("Insufficient balance for the transaction")
          } else {
            setErrTest("Oops, something went wrong")
          }
          setShow2(true)
          setLoarder(false)

        }
      } else {
        setLoarder(false)
        toast.error("Insufficient balance")
      }
    }

  }

  useEffect(() => {
    document.body.classList.add('buycomponent-bgremoved');

    return () => {
      document.body.classList.remove('buycomponent-bgremoved');
    };
  }, []);

  return (
    <>
      {loaderr1 && (
        <Loader />
      )}
      {loaderr && (
        <Loader text={"Please wait, your transaction is in process"} />
      )}
      <Navbar setShow={setShow} />
      <section className="main-banner">
        <div className="custom-container">
          <div className="banner-content">
            <img src="\assets\buy-card-bg.png" alt="img" className="img-fluid buy-card-right-bottom" />
            <div className="left-side-content">
              <div className="twice-content">
                <div className="upper-content">
                  <div className="left">
                    <img
                      src="\assets\newvion-logo.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <div className="text">
                      <h6>VION</h6>
                      <p>VION</p>
                    </div>
                  </div>
                  <div className="right">
                    <h6>$0.013 USDT</h6>
                  </div>
                </div>
              </div>
              <div className="main-nft-img text-center">
                <img width={420} height={416} src="\nft-img\vion-nft.png" alt="img" className="img-fluid" />
              </div>
            </div>
            <div className="new-style-box">
              <div className="bottom-content">
                <div className="twice-data">
                  <h6 className="main-head">Buy your VION Genesis NFT</h6>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={tagImge}
                        alt="img"
                        className="img-fluid suxvsvsyucsusc"
                      />
                      <div className="text">
                        <h6>{mainTag}</h6>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="4" viewBox="0 0 7 4" fill="none">
                        <path d="M6.84056 0.963036L5.23504 2.64311L4.25472 3.67419C3.83958 4.1086 3.16436 4.1086 2.74922 3.67419L0.158378 0.963036C-0.181733 0.607131 0.0633473 0 0.538503 0H3.34442H6.46044C6.94059 0 7.18067 0.607131 6.84056 0.963036Z" fill="white" />
                      </svg>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item gscrxcsx" onClick={() => handlerTag("ETH")}>
                          <img
                            src="/assets/eth-icon.svg"
                            alt="img"
                            className="img-fluid"
                          />{" "}
                          ETH
                        </a>
                      </li>
                      <li className="mb-0">
                        <a class="dropdown-item gscrxcsx" onClick={() => handlerTag("USDT")}>
                          <img
                            src="/assets/usdt.svg"
                            alt="img"
                            className="img-fluid"
                          />{" "}
                          USDT
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="parent-box">
                  <div className="bottom-select-amount">
                    <h6>Select the amount of VION Genesis NFTs you want to purchase</h6>
                    <div className="amount-div">

                      <a className={totalNft > 0 ? "minus-btn active" : "minus-btn"} onClick={() => handleDecrement(totalNft)}>-</a>
                      {
                        totalNft > 0 ? <span>{totalNft}</span> : <span style={{color: "#3A3A3A"}}>{totalNft}</span>
                      }
                      <a className="plus-btn" onClick={() => handleIncrement(totalNft)}>+</a>
                    </div>
                  </div>

                </div>
                <div className="bottom-text">
                  <div className="inner-text-bold pt-0">
                    <h6 className="specific-color">NFTs you get:</h6>
                    <h6>
                      {totalNft}
                    </h6>
                  </div>
                  <div className="inner-text-bold">
                    <h6 className="specific-color">VION tokens you get:</h6>
                    <h6>
                      {totalToken1.toFixed(3)} VION
                    </h6>
                  </div>
                  <div className="inner-text-bold pb-0">
                    <h6 className="specific-color">Total You Pay</h6>
                    <h6 style={{color: "#2DD98F"}}>
                      {mainTag == "ETH" ? totalAmount1.toFixed(4) : totalAmount1} {mainTag == "ETH" ? "ETH" : "USDT"}
                    </h6>
                  </div>


                </div>
                <div className="progress-bar-container">
                  <ProgressBar now={100 * (parseInt(balaceOfDoppp) / 1000)} />
                  <div className="inside-text">
                    <p>Total NFTs Minted</p>
                    <h6>{parseInt(balaceOfDoppp)} / 1000</h6>
                  </div>
                </div>
                {account ?
                  <>
                    {totalNft > 0 ?

                      <button className="newClasss" onClick={contractCall}>
                        Proceed to Purchase
                      </button>
                      :
                      <>
                        {balaceOfDoppp === 1000 ?
                          <button className="newClasss" >
                            All NFTs have been sold
                          </button>
                          :
                          <button className="proceedtopurchase" >
                            Proceed to Purchase
                          </button>

                        }
                      </>

                    }
                  </>
                  :
                  <button className="newClasss" onClick={() => setShow(true)}>
                    Connect Wallet
                  </button>
                }
              </div>
            </div>

          </div>
        </div>
      </section>
      <Modal
        className="connectwallet-modal buy-modal"
        show={show1}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>VION Genesis NFT Purchased</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\purchased.svg" alt="img" className="img-fluid" />
            <p style={{ fontSize: "22px", fontWeight: "900" }}>
              You have successfully purchased {" "} <br />
              <span>
                {/* {totalToken.toFixed(3)} */}
                {" "}
                Vion Genesis NFT</span>
              {/* You can claim your DOP on <span>Q1,2024</span> */}
            </p>
            <button className="btn-common" onClick={handleClose}>
              View Claims
            </button>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        className="connectwallet-modal buy-modal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>VION Purchased</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\oops.svg" alt="img" className="img-fluid" />
            <p>
              {errortest}

            </p>
            <button className="btn-common" onClick={handleClose2}>
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>




    </>
  );
};

export default Banner;

import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import { toast } from "react-toastify";

const PurchaseEth = () => {
  // const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);
  const purchaseEth = useCallback(
    async (nftAmount,value) => {
let  nd = web3.utils.toWei(value.toString(), "ether");
let nft=nftAmount.toString();
      let gas
      // console.log(postApiRes.code,id,postApiRes.deadline,minvalue,stakeIndex,postApiRes?.v, postApiRes?.r, postApiRes?.s,index,'nooor');
      try {
        let gasPrice = await web3.eth.getGasPrice()
        gasPrice = parseInt(gasPrice) + 3000000000
         gas = await contract.methods
          .purchaseNFWithETH(nft)
          .estimateGas({
            value: nd,
            from: account,
            gasPrice
          });
        const details = await contract.methods
          .purchaseNFWithETH(nft)
          .send({
            value: nd,
            from: account,
            gas: gas,
            gasPrice
          });
        return details;
      } catch (error) {
        // let gasPrice1=await web3.eth.getGasPrice()
        // let gasPrice = parseInt(gasPrice1) + 3000000000
        // gas=gas*gasPrice
        // let nd = web3.utils.fromWei(gas.toString(), "ether");
        // console.log(gas,nd,balance );
        // if(nd>balance){
        //    toast.error("Insufficient ETH for Transaction")
        // }else{
          throw error;
        // }
      }
    },
    [contract, account,web3]
  );
  return { purchaseEth: purchaseEth,web3 };
};
export default PurchaseEth;
